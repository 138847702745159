import React, { useRef, useContext, useEffect } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Img from "gatsby-image"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { motion, useAnimation } from "framer-motion"

import { useOnScreen } from "../../hooks/"
import Context from "../../context/"
import ContentWrapper from "../../styles/contentWrapper"
import useIsMobileScreen from '../../hooks/useIsMobileScreen'

const StyledSection = styled.section`
  width: 100%;
  height: auto;
  background: ${({ theme }) => theme.colors.background};
  margin-top: 1rem;
`

const StyledContentWrapper = styled(ContentWrapper)`
  && {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
      flex-direction: row;
      justify-content: space-between;
    }
    .section-title {
      margin-bottom: 2rem;
    }
    .inner-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .text-content {
      width: 100%;
      max-width: 31.25rem;
    }
    .image-content {
      width: 100%;
      max-width: 18rem;
      margin-top: 4rem;
      margin-left: 0;

      display:flex;
      justify-content:center;
      align-items:center;

      @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
        margin-left: 2rem;
      }
    }
    .about-author {
      border-radius: ${({ theme }) => theme.borderRadius};
      box-shadow: 0 0 2.5rem rgba(0, 0, 0, 0.16);
      filter: grayscale(20%) contrast(1) brightness(90%);
      transition: all 0.3s ease-out;
      &:hover {
        filter: grayscale(50%) contrast(1) brightness(90%);
        transform: translate3d(0px, -0.125rem, 0px);
        box-shadow: 0 0 2.5rem rgba(0, 0, 0, 0.32);
      }
    }
  }
`

const About = ({ content, lang }) => {
    const { frontmatter, body } = content[0].node
    const { isIntroDone = true } = useContext(Context).state
    const isMobile = useIsMobileScreen();
    const tControls = useAnimation()
    const iControls = useAnimation()

    // Required for animating the text content
    const tRef = useRef()
    const tOnScreen = useOnScreen(tRef)

    // Required for animating the image
    const iRef = useRef()
    const iOnScreen = useOnScreen(iRef)

    // Only trigger animations if the intro is done or disabled
    useEffect(() => {
        if (isIntroDone) {
            if (tOnScreen) tControls.start({ opacity: 1, y: 0 })
            if (iOnScreen) iControls.start({ opacity: 1, x: 0 })
        }
    }, [isIntroDone, tControls, iControls, tOnScreen, iOnScreen])

    const genItemComp = item => {
        const layoutStyle = isMobile ? {
            display: 'flex',
            flexDirection: 'column',
            flexWrap: 'wrap',
        } : {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
        }

        const arrowColor = '#005C78'

        if (item[2]) {
            return (
                <a key={item[0]} style={{ ...layoutStyle, width: '100%', marginTop: 20 }} href={item[2]} alt={item[0]} target='_blank' rel="noreferrer">
                    <div>
                        <h4 style={{ marginRight: 10, color: '#2C3639', display: 'inline-block', marginTop: 0, marginBottom: 0 }}>{item[0]}</h4>
                        {isMobile && <a href={item[2]} alt={item[0]} target='_blank' rel="noreferrer" style={{ color: arrowColor }}> ▶</a>}
                    </div>
                    <div style={{ color: '#A27B5C' }}>{item[1]}</div>
                    {!isMobile && <a style={{ marginLeft: 10, color: arrowColor }}>  ▶</a>}
                </a>
            )
        }

        return (
            <div key={item[0]} style={{ ...layoutStyle, width: '100%', marginTop: 20 }}>
                <h4 style={{ marginRight: 20, color: '#2C3639', display: 'inline-block', marginTop: 0, marginBottom: 0 }}>{item[0]}</h4>
                <div style={{ color: '#A27B5C' }}>{item[1]}</div>
            </div>
        )
    }



    // EN //

    if (lang === 'en') {

        return (
            <StyledSection id="about">
                <StyledContentWrapper>
                    <motion.div
                        className="inner-wrapper"
                        ref={tRef}

                    >
                        <h2 className="section-title">About</h2>
                        <p style={{ margin: 0, fontSize: '1.3rem', color: 'grey' }}>Development / Consulting / Operations.</p>
                        <div className="text-content">
                            <p>
                                Started with embedded systems, from bare-metal programming to Linux drivers. Worked on Android Framework and research related to sensors and audio. Later, ventured into the world of APP, Web and Cloud.
                            </p>
                            <p>
                                In recent years, in addition to development work, I have also assisted in technical consulting, team management, and product design. I have served as CTO and technical advisor in multiple companies. I have participated in more than 50 projects and product developments spanning various fields such as hospitality, finance, education, printing, e-commerce, healthcare, and telecommunications.
                            </p>
                            <p>
                                Continuously exploring the balance between technology and business models.
                            </p>

                            <hr style={{ width: 150, border: '2px solid grey', marginLeft: 0, marginTop: 10, marginBottom: 10 }} />

                            <h3 style={{ fontWeight: 'bold', marginBottom: 20 }}>■ IoT</h3>
                            {[
                                ['Smart Bluetooth lock APP/WEB/Cloud/Firmware', 'Technical consulting | Product design | System development.', 'https://medium.com/revtel-tech/案例分享-傳產升級-藍牙電子鎖-809b39d97637'],
                                ['NFC Tag APP/WEB/Cloud/IoT ', 'Product design | Business collaboration | System development', 'https://medium.com/revtel-tech/nfc-怎麼用-寫在-iphone-終於完整支援-nfc-讀寫的今天-3b88ed308a38'],
                            ].map(item => {
                                const applyLink = comp => {
                                    const h4Comp = <h4 style={{ display: 'inline-block', marginTop: 0, marginBottom: 0 }}>{comp}</h4>
                                    return item[2] ? <a style={{ textDecorationLine: 'underline', textDecorationColor: 'lightgrey' }} href={item[2]} target='_blank' rel="noreferrer" alt={comp} aria-label={comp}>{h4Comp}</a> : h4Comp
                                }
                                return (
                                    <div key={item[0]} style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '100%', marginTop: 20 }}>
                                        <div style={{ marginRight: 20, color: '#2C3639' }}>{applyLink(item[0])}</div>
                                        <div style={{ color: '#A27B5C' }}>{item[1]}</div>
                                    </div>
                                )
                            })}
                            <h3 style={{ fontWeight: 'bold', marginBottom: 20 }}>■ Web/APP/Cloud</h3>
                            {[
                                ['Singaporean unicorn-level e-commerce company APP ', 'System development'],
                                ['Hotel mobile concierge APP/WEB', 'Product design | Business collaboration | System development | Team building'],
                                ['Telecommunications startup mobile solution APP/WEB/System', 'Product design | Business collaboration | Technical consulting | System development | Team building', 'https://medium.com/revtel-tech/案例分享-tel-u-電信電商-8cc7a7feefb4'],
                                ['Interactive flipped classroom platform for education WEB', 'Product design | Business collaboration | System development', 'https://medium.com/revtel-tech/案例分享-線上教育平台-qritica-b5974cf719c2'],
                                ['FinTech personal investment mobile APP', 'Product design | System development | Team training'],
                                ['Stock market monitoring & investment APP', 'Product design | System development', 'https://medium.com/revtel-tech/兼顧互動及專業的線圖-以股票看盤-軟體為例來聊聊-web-app-的合併開發-3bed0589a233'],
                                ['Printing and creative e-commerce system', 'Product design | Business collaboration | System development | Team building', 'https://medium.com/revtel-tech/印刷業專屬一站式解決方案-hiprint-印刷電子商務新零售-227730c29dea'],
                                ['Healthcare IoT device startup', 'Cloud architecture consultant'],
                                ['Factory smart automation software', 'Technical consulting | System development', 'https://medium.com/revtel-tech/淺談系統設計的因地至宜-從產線監控軟體開發設計談起-a90bd6658fde'],
                                ['Chain restaurant ordering system Web / APP', 'Technical consulting | System development',],
                                ['Laya Burger Web / APP', 'Technical consulting | System development',],
                                ['Taipei Bar Association Web / APP', 'Technical consulting | System development',],
                                ['Tawan Bar Association APP', 'Technical consulting | System development',],
                                ['Tawan Patent Attorneys Association Web', 'Technical consulting | System development',],
                            ].map(item => {
                                const applyLink = comp => {
                                    const h4Comp = <h4 style={{ display: 'inline-block', marginTop: 0, marginBottom: 0 }}>{comp}</h4>
                                    return item[2] ? <a style={{ textDecorationLine: 'underline', textDecorationColor: 'lightgrey' }} href={item[2]} target='_blank' rel="noreferrer" alt={comp} aria-label={comp}>{h4Comp}</a> : h4Comp
                                }
                                return (
                                    <div key={item[0]} style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '100%', marginTop: 20 }}>
                                        <div style={{ marginRight: 20, color: '#2C3639' }}>{applyLink(item[0])}</div>
                                        <div style={{ color: '#A27B5C' }}>{item[1]}</div>
                                    </div>
                                )
                            })}
                            <h3 style={{ fontWeight: 'bold', marginBottom: 20 }}>■ BlockChain</h3>
                            {[
                                ['NFC ColdWallet APP/IoT', 'Product design | Business collaboration | System development', 'https://tw.stock.yahoo.com/news/%E5%B0%88%E8%A8%AA-%E5%B0%87%E6%8E%A8%E5%87%BA%E5%8F%B0%E8%A3%BDnfc%E5%86%B7%E9%8C%A2%E5%8C%85-inigma-%E5%8A%A0%E5%AF%86%E9%8F%88%E7%A7%91%E6%8A%80ceo%E9%BB%83%E5%A5%95%E7%BF%94-%E7%9B%AE%E6%A8%99%E6%98%AF%E5%B0%87%E5%86%B7%E9%8C%A2%E5%8C%85%E8%AE%8A%E6%88%90%E4%BA%BA%E4%BA%BA%E7%9A%86%E5%8F%AF%E6%93%81%E6%9C%89%E7%9A%84%E6%82%A0%E9%81%8A%E5%8D%A1-090000791.html'],
                                ['Smart Business District Coin Issuing Platform', 'Product design | System development', 'https://medium.com/revtel-tech/智慧商圈點數及區塊鏈-點數的多種表述-c423ee46eaaf'],
                                ['NFT Project Launch/Smart Contract/E-commerce', 'Technical consulting | Product design | Business collaboration | System development'],
                                ['Artwork Web3 Certification and Membership System', 'Technical consulting | Product design | Business collaboration | System development']
                            ].map(item => {
                                const applyLink = comp => {
                                    const h4Comp = <h4 style={{ display: 'inline-block', marginTop: 0, marginBottom: 0 }}>{comp}</h4>
                                    return item[2] ? <a style={{ textDecorationLine: 'underline', textDecorationColor: 'lightgrey' }} href={item[2]} target='_blank' rel="noreferrer" alt={comp} aria-label={comp}>{h4Comp}</a> : h4Comp
                                }
                                return (
                                    <div key={item[0]} style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '100%', marginTop: 20 }}>
                                        <div style={{ marginRight: 20, color: '#2C3639' }}>{applyLink(item[0])}</div>
                                        <div style={{ color: '#A27B5C' }}>{item[1]}</div>
                                    </div>
                                )
                            })}





                        </div>
                    </motion.div>
                    <motion.div
                        className="image-content"
                        ref={iRef}
                        initial={{ opacity: 0, x: 20 }}
                        animate={iControls}
                    >
                        <img
                            className="about-author"
                            src='/rt/1.png'
                            width='330'
                            alt='Happy Day'
                        />
                    </motion.div>
                </StyledContentWrapper>
            </StyledSection>
        )
    }


    // TW //

    if (lang === 'tw') {

        return (
            <StyledSection id="about">
                <StyledContentWrapper>
                    <motion.div
                        className="inner-wrapper"
                        ref={tRef}

                    >
                        <h2 className="section-title">關於</h2>
                        <p style={{ margin: 0, fontSize: '1.3rem', color: 'grey' }}>開發 / 顧問 / 營運</p>
                        <div className="text-content">
                            <p>工程師。碰過嵌入式系統、Linux / Android、sensor 及 audio，而後 APP、Web、雲端及一點點區塊鏈。
                            </p>
                            <p>
                                開發外亦協助技術顧問、團隊管理及產品設計，曾在多間公司擔任技術長及技術顧問。參與超過 60 個專案、產品開發，橫跨旅宿、金融、教育、印刷、電商、醫療及電信等多個領域。
                            </p>
                            <p>
                                尋找技術和商業模式之間的平衡。
                            </p>

                            <hr style={{ width: 150, border: '2px solid grey', marginLeft: 0, marginTop: 10, marginBottom: 10 }} />

                            <h3 style={{ fontWeight: 'bold', marginBottom: 20 }}>■ 物聯網</h3>
                            {[
                                ['智能藍牙鎖 APP/WEB/Firmware', '技術顧問 | 產品設計 | 系統開發', 'https://medium.com/revtel-tech/案例分享-傳產升級-藍牙電子鎖-809b39d97637'],
                                ['NFC 雲標籤 APP/WEB/Cloud/IoT ', '產品設計 | 商務協力 | 系統開發', 'https://medium.com/revtel-tech/nfc-怎麼用-寫在-iphone-終於完整支援-nfc-讀寫的今天-3b88ed308a38'],
                                ['NFC 電子名片 APP/WEB/IoT ', '產品設計 | 商務協力 | 系統開發', 'https://www.nfctogo.com/'],
                            ].map(genItemComp)}
                            <h3 style={{ fontWeight: 'bold', marginBottom: 20 }}>■ Web/APP/Cloud</h3>
                            {[
                                ['新加坡獨角獸電商 APP (估值10億美元以上)', '系統開發'],
                                ['飯店行動管家 APP/WEB', '產品設計 | 商務協力 | 系統開發 | 團隊建立'],
                                ['電信新創行動方案 APP/WEB/System', '產品設計 | 商務協力 | 技術顧問 | 系統開發 | 團隊建立', 'https://medium.com/revtel-tech/案例分享-tel-u-電信電商-8cc7a7feefb4'],
                                ['Qritica 翻轉教育 WEB', '產品設計 | 商務協力 | 系統開發', 'https://medium.com/revtel-tech/案例分享-線上教育平台-qritica-b5974cf719c2'],
                                ['FinTech 個人投資 APP', '產品設計 | 系統開發 | 團隊訓練 '],
                                ['股票看盤 & 投資 APP', '產品設計 | 系統開發', 'https://www.revtel.tech/project/gp-stock'],
                                ['HiPrint 印刷電商系統', '產品設計 | 商務協力 | 系統開發 | 團隊建立', 'https://medium.com/revtel-tech/印刷業專屬一站式解決方案-hiprint-印刷電子商務新零售-227730c29dea'],
                                ['Pranaq 醫療 IoT 設備', '雲端架構顧問'],
                                ['DSA 達詳自動化軟體', '技術顧問 | 系統開發', 'https://medium.com/revtel-tech/淺談系統設計的因地至宜-從產線監控軟體開發設計談起-a90bd6658fde'],
                                ['拉亞漢堡點餐網(Web/APP)', '技術顧問 | 系統開發', 'https://www.revtel.tech/project/laya-order-web-and-app'],
                                ['台北律師公會(Web/APP)', '技術顧問 | 系統開發', 'https://www.revtel.tech/project/taipei-bar-association-web-app'],
                                ['全國律師聯合會 APP', '技術顧問 | 系統開發'],
                                ['中華民國專利師公會(Web)', '技術顧問 | 系統開發']
                            ].map(genItemComp)}
                            <h3 style={{ fontWeight: 'bold', marginBottom: 20 }}>■ 區塊鏈</h3>
                            {[
                                ['區塊鏈 NFC 冷錢包 APP/IoT', '產品設計 | 商務協力 | 系統開發', 'https://tw.stock.yahoo.com/news/%E5%B0%88%E8%A8%AA-%E5%B0%87%E6%8E%A8%E5%87%BA%E5%8F%B0%E8%A3%BDnfc%E5%86%B7%E9%8C%A2%E5%8C%85-inigma-%E5%8A%A0%E5%AF%86%E9%8F%88%E7%A7%91%E6%8A%80ceo%E9%BB%83%E5%A5%95%E7%BF%94-%E7%9B%AE%E6%A8%99%E6%98%AF%E5%B0%87%E5%86%B7%E9%8C%A2%E5%8C%85%E8%AE%8A%E6%88%90%E4%BA%BA%E4%BA%BA%E7%9A%86%E5%8F%AF%E6%93%81%E6%9C%89%E7%9A%84%E6%82%A0%E9%81%8A%E5%8D%A1-090000791.html'],
                                ['智慧商圈發幣平台', '產品設計 | 系統開發', 'https://medium.com/revtel-tech/智慧商圈點數及區塊鏈-點數的多種表述-c423ee46eaaf'],
                                ['知名 NFT 項目發行/智能合約/電商', '技術顧問 | 產品設計 | 商務協力 | 系統開發'],
                                ['藝術品 Web3 憑證及會員系統', '技術顧問 | 產品設計 | 系統開發']
                            ].map(genItemComp)}





                        </div>
                    </motion.div>
                    <motion.div
                        className="image-content"
                        ref={iRef}
                        initial={{ opacity: 0, x: 20 }}
                        animate={iControls}
                    >
                        <img
                            className="about-author"
                            src='/rt/1.png'
                            width='330'
                            alt='Happy Day'
                        />
                    </motion.div>
                </StyledContentWrapper>
            </StyledSection>
        )
    }




}

About.propTypes = {
    content: PropTypes.arrayOf(
        PropTypes.shape({
            node: PropTypes.shape({
                body: PropTypes.string.isRequired,
                frontmatter: PropTypes.object.isRequired,
            }).isRequired,
        }).isRequired
    ).isRequired,
}

export default About
